import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import translationEn from './locales/en/translation.json';
import translationFr from './locales/fr/translation.json';
import translationNl from './locales/nl/translation.json'
//translations
const resources = {
    nl: {
        translation: translationNl,
    },
    fr: {
        translation: translationFr
    },
    en: {
        translation: translationEn
    }
};

i18n.use(detector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources: resources,
        // lng: 'en',
        fallbackLng: 'en', // use en if detected lng is not available
        lng: localStorage.getItem('language') || 'en',  // Load language from localStorage if available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;

// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';

// i18n
//     .use(initReactI18next)
//     .use(LanguageDetector)
//     .use(HttpApi)
//     .init({
//         supportedLngs: ['en', 'fr', 'nl'],
//         fallbackLng: 'en',
//         detection: {
//             order: ['path', 'cookie', 'localStorage', 'navigator'],
//             caches: ['cookie', 'localStorage'],
//         },
//         backend: {
//             loadPath: '/locales/{{lng}}.json',
//         },
//     });

// export default i18n;


// import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
// import HttpApi from 'i18next-http-backend';

// i18n
//     .use(initReactI18next)
//     .use(LanguageDetector)
//     .use(HttpApi)
//     .init({
//         supportedLngs: ['en', 'fr', 'nl'],
//         fallbackLng: 'en',
//         lng: localStorage.getItem('language') || 'en',  // Load language from localStorage if available
//         detection: {
//             order: ['path', 'cookie', 'localStorage', 'navigator'],
//             caches: ['cookie', 'localStorage'],
//         },
//         backend: {
//             loadPath: '/locales/{{lng}}.json',
//         },
//     });

// export default i18n;
