export const Charges = {
  GET_CHARGES: "GET_CHARGES",
  GET_CHARGES_LOADING: "GET_CHARGES_LOADING",
  GET_CHARGES_SUCCESS: "GET_CHARGES_SUCCESS",
  GET_CHARGES_ERROR: "GET_CHARGES_ERROR",
  GET_CHARGES_RESET: "GET_CHARGES_RESET",

  GET_CURRENT_PLANS_DETAILS: "GET_CURRENT_PLANS_DETAILS",
  GET_CURRENT_PLANS_DETAILS_LOADING: "GET_CURRENT_PLANS_DETAILS_LOADING",
  GET_CURRENT_PLANS_DETAILS_SUCCESS: "GET_CURRENT_PLANS_DETAILS_SUCCESS",
  GET_CURRENT_PLANS_DETAILS_ERROR: "GET_CURRENT_PLANS_DETAILS_ERROR",
  GET_CURRENT_PLANS_DETAILS_RESET: "GET_CURRENT_PLANS_DETAILS_RESET",

  UPGRADE_CURRENT_PLANS_DETAILS: "UPGRADE_CURRENT_PLANS_DETAILS",
  UPGRADE_CURRENT_PLANS_DETAILS_LOADING: "UPGRADE_CURRENT_PLANS_DETAILS_LOADING",
  UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS: "UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS",
  UPGRADE_CURRENT_PLANS_DETAILS_ERROR: "UPGRADE_CURRENT_PLANS_DETAILS_ERROR",
  UPGRADE_CURRENT_PLANS_DETAILS_RESET: "UPGRADE_CURRENT_PLANS_DETAILS_RESET",
};
