import { searchAccountantType } from "./constant";

export const searchaccountantAction = (data): AuthAction => ({
    type: searchAccountantType.SEARCH_ACCOUNTANT,
    payload: data,
});

export const getIndustoryAction = (data) => ({
    type: searchAccountantType.GET_FIRST_INDUSTORY,
    payload: data,
});

export const getApiTokenOfCalendly = (data) => ({
    type: searchAccountantType.GET_FIRST_CALENDLY_API_KEY,
    payload: data,
});

export const getAmoutAction = (data) => ({
    type: searchAccountantType.GET_FIRST_AMOUNT,
    payload: data,
});

export const sendInvitation = (data) => ({
    type: searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT,
    payload: data,
});

export const getSentInvitation = (data) => ({
    type: searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT,
    payload: data,
});

export const postAccountValidateStripe = (data) => {
    return {
        type: searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_,
        payload: data,
    };
};

export const updateAccountValidateStripe = (data) => {
    return {
        type: searchAccountantType.UPDATE_ACCOUNT_VALIDATE_STRIPE_,
        payload: data,
    };
};

export const getAccountantDetails = (data) => ({
    type: searchAccountantType.GET_FIRST_ACCOUNTANT_DETAILS,
    payload: data,
});

export const getClientAccountantDetails = (data) => ({
    type: searchAccountantType.GET_FIRST_CLIENT_ACCOUNTANT_DETAILS,
    payload: data,
});

export const updatePaymentMethod = (data) => ({
    type: searchAccountantType.UPDATE_PAYMENT_METHOD_FIRST__DETAILS,
    payload: data,
});

export const generateContract = (data) => ({
    type: searchAccountantType.GENERATE_CONTRACT_FIRST__DETAILS,
    payload: data,
});
export const getApiTokenOfDocusign = (data) => ({
    type: searchAccountantType.GET_FIRST_DOCUSIGN_API_KEY,
    payload: data,
});
export const getContractPageDate = (data) => ({
    type: searchAccountantType.GET_FIRST_CONTRACT_LIST,
    payload: data,
});
export const getClientContract = (data) => ({
    type: searchAccountantType.GET_FIRST_CLIENT_CONTRACT_LIST,
    payload: data,
});

export const cancelCurrentSubscription = (data) => {
    return {
        type: searchAccountantType.CANCEL_CURRENT_SUBSCRIPTION_PLAN,
        payload: data,
    };
};

export const renewSubscription = (data): AuthAction => ({
    type: searchAccountantType.RENEW_SUBSCRIPTION,
    payload: data,
});

export const downgradeSubscription = (data): AuthAction => ({
    type: searchAccountantType.DOWNGRADE_CURRENT_PLANS_DETAILS,
    payload: data,
});
