import { all, fork, put, takeEvery, call } from "redux-saga/effects";
import { Charges } from "./constant";
import { getChargesEndPoint, getCurrentPlanDetailsEndPoint, upgradeCurrentPlanEndPoint } from "./api";

function* getChargesFunction(data) {
  try {
    yield put({
      type: Charges.GET_CHARGES_LOADING,
      payload: {},
    });
    const response = yield call(getChargesEndPoint, data);
    if (response.data.status) {
      yield put({
        type: Charges.GET_CHARGES_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: Charges.GET_CHARGES_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: Charges.GET_CHARGES_RESET,
      payload: error?.data,
    });
  }
}

function* getCurrentPlanDetailsFunction(data) {
  try {
    yield put({
      type: Charges.GET_CURRENT_PLANS_DETAILS_LOADING,
      payload: {},
    });
    const response = yield call(getCurrentPlanDetailsEndPoint, data);
    if (response.data.status) {
      yield put({
        type: Charges.GET_CURRENT_PLANS_DETAILS_SUCCESS,
        payload: { ...response.data },
      });
    } else {
      yield put({
        type: Charges.GET_CURRENT_PLANS_DETAILS_ERROR,
        payload: { ...response.data },
      });
    }
  } catch (error) {
    yield put({
      type: Charges.GET_CURRENT_PLANS_DETAILS_RESET,
      payload: error?.data,
    });
  }
}

function* upgradeCurrentPlanFunction({ payload }) {
  try {
    yield put({
      type: Charges.UPGRADE_CURRENT_PLANS_DETAILS_LOADING,
    });

    // Pass payload directly if it's the correct structure
    const response = yield call(upgradeCurrentPlanEndPoint, payload);

    if (response.data.status) {
      yield put({
        type: Charges.UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS,
        payload: response.data,
      });
      yield put({
        type: Charges.UPGRADE_CURRENT_PLANS_DETAILS_RESET,
      });
    } else {
      yield put({
        type: Charges.UPGRADE_CURRENT_PLANS_DETAILS_ERROR,
        payload: response.data,
      });
    }
  } catch (error) {
    yield put({
      type: Charges.UPGRADE_CURRENT_PLANS_DETAILS_ERROR,
      payload: error?.response?.data || error.message,  // Ensure you're accessing the correct error data
    });
  }
}


export function* ChargesWatcher(): any {
  yield takeEvery(Charges.GET_CHARGES, getChargesFunction);
}
export function* currentPlaneDetailsWatcher(): any {
  yield takeEvery(Charges.GET_CURRENT_PLANS_DETAILS, getCurrentPlanDetailsFunction);
}
export function* upgradeCurrentPlaneWatcher(): any {
  yield takeEvery(Charges.UPGRADE_CURRENT_PLANS_DETAILS, upgradeCurrentPlanFunction);
}

function* AllChargesSaga(): any {
  yield all([
    // fork(acctionAddRating),
    fork(ChargesWatcher),
    fork(currentPlaneDetailsWatcher),
    fork(upgradeCurrentPlaneWatcher),
  ]);
}
export default AllChargesSaga;
