import { APICore } from "../../../helpers/api/apiCore";
import * as URL from "../../../helpers/api/apiEndPoint";

const api = new APICore();

export function getChargesEndPoint(params) {
  const { data } = params;
  return api.get(URL.GET_CHARGES, data);
}

export function getCurrentPlanDetailsEndPoint(params) {
  const { data } = params;
  return api.get(URL.GET_CURRENT_PLAN_DETAILS, data);
}

export function upgradeCurrentPlanEndPoint(data) {
  return api.update(`${URL.UPGRADE_SUBSCRIPTION}`, { subscriptionId: data?.subscriptionId, clientId: data?.clientId })
}
