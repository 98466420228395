import { Charges } from "./constant";

const Initial_State = {
  data: [],
  loading: false,
  message: "",
};

export const getChargesReducer = (state = Initial_State, action) => {
  switch (action.type) {
    case Charges.GET_CHARGES_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case Charges.GET_CHARGES_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case Charges.GET_CHARGES_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const getCurrentPlanDetailsReducer = (state = Initial_State, action) => {
  switch (action.type) {
    case Charges.GET_CURRENT_PLANS_DETAILS_LOADING:
      return {
        data: state.data,
        loading: true,
      };
    case Charges.GET_CURRENT_PLANS_DETAILS_SUCCESS:
      return {
        data: action.payload,
        loading: false,
      };
    case Charges.GET_CURRENT_PLANS_DETAILS_ERROR:
      return {
        data: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export const updateCurrentPlanReducers = (state = Initial_State, action) => {
  switch (action.type) {
    case Charges.UPGRADE_CURRENT_PLANS_DETAILS_LOADING:
      return {
        data: Initial_State.data,
        loading: true,
      };
    case Charges.UPGRADE_CURRENT_PLANS_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.payload,  // Ensure you directly assign payload here
        loading: false,
      };
    case Charges.UPGRADE_CURRENT_PLANS_DETAILS_RESET:
      return {
        data: action?.payload,
        loading: false,
      };
    case Charges.UPGRADE_CURRENT_PLANS_DETAILS_ERROR:
      return {
        data: state,
        loading: false,
      };
    default:
      return { ...state };
  }
};
