import { searchAccountantType } from "./constant";

const initial_state = {
    data: [],
    message: "",
    loading: false,
};


export const searchAccountantReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.SEARCH_ACCOUNTANT_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.SEARCH_ACCOUNTANT_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.SEARCH_ACCOUNTANT_ERROR:
            return {
                data: action.payload,
                loading: false,
            };
        // case searchAccountantType.SEARCH_ACCOUNTANT_RESET:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return { ...state };
    }
};

export const getIndustoryReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_INDUSTORY:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_INDUSTORY:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_INDUSTORY:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.SEARCH_ACCOUNTANT_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const getCalendlyTokenReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_CALENDLY_API_KEY:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_CALENDLY_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_CALENDLY_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_RESET_CALENDLY_API_KEY:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const sendInvitationReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.SEND_INVITATION_TO_ACCOUNTANT_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const getSentInvitationReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_SUCCESS:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_SENT_INVITATION_TO_ACCOUNTANT_ERROR:
            return {
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const sendAccountValidateStripeReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.POST_ACCOUNT_VALIDATE_STRIPE_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const updateAccountValidateStripeReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.UPDATE_ACCOUNT_VALIDATE_STRIPE_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.UPDATE_ACCOUNT_VALIDATE_STRIPE_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.UPDATE_ACCOUNT_VALIDATE_STRIPE_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.UPDATE_ACCOUNT_VALIDATE_STRIPE_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};


export const getAccountantDetailsReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_ACCOUNTANT_DETAILS:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_ACCOUNTANT_DETAILS:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_ACCOUNTANT_DETAILS:
            return {
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const getClientAccountantDetailsReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_CLIENT_ACCOUNTANT_DETAILS:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_CLIENT_ACCOUNTANT_DETAILS:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_CLIENT_ACCOUNTANT_DETAILS:
            return {
                data: action.payload,
                loading: false,
            };
        default:
            return state;
    }
};

export const updatePaymentMethodReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.UPDATE_PAYMENT_METHOD_LOADING__DETAILS:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.UPDATE_PAYMENT_METHOD_SUCCESS__DETAILS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.UPDATE_PAYMENT_METHOD_RESET__DETAILS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.UPDATE_PAYMENT_METHOD_ERROR__DETAILS:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};
export const generateContractReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GENERATE_CONTRACT_LOADING__DETAILS:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GENERATE_CONTRACT_SUCCESS__DETAILS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.GENERATE_CONTRACT_RESET__DETAILS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.GENERATE_CONTRACT_ERROR__DETAILS:
            return {
                data: state,
                loading: false,
            };
        case searchAccountantType.GENERATE_CONTRACT_ERROR__DETAILS:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const getDocusignTokenReducers = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_DOCUSIGN_API_KEY:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_DOCUSIGN_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_DOCUSIGN_API_KEY:
            return {
                data: action.payload,
                loading: false,
            };
        // case searchAccountantType.GET_RESET_DOCUSIGN_API_KEY:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return state;
    }
};

export const getAmountReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_AMOUNT:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_AMOUNT:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_AMOUNT:
            return {
                data: action.payload,
                loading: false,
            };
        // case searchAccountantType.GET_RESET_DOCUSIGN_API_KEY:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return state;
    }
};


export const getContractListReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_CONTRACT_LIST:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_CONTRACT_LIST:
            return {
                data: action.payload,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_CONTRACT_LIST:
            return {
                data: action.payload,
                loading: false,
            };
        // case searchAccountantType.GET_RESET_CONTRACT_LIST:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        default:
            return state;
    }
};


export const getClientContractContractReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.GET_LOADING_CLIENT_CONTRACT_LIST:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.GET_SUCCESS_CLIENT_CONTRACT_LIST:
            return {
                data: action?.payload,
                loading: false,
            };
        // case searchAccountantType.GET_RESET_CLIENT_CONTRACT_LIST:
        //     return {
        //         data: action?.payload,
        //         loading: false,
        //     };
        case searchAccountantType.GET_ERROR_CLIENT_CONTRACT_LIST:
            return {
                data: state,
                loading: false,
            };
        case searchAccountantType.GET_ERROR_CLIENT_CONTRACT_LIST:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

const initialState = {
    loading: false,
    success: false,
    error: null,
};

export const accountValidationReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'VALIDATE_ACCOUNT_REQUEST':
            return { ...state, loading: true, error: null };
        case 'VALIDATE_ACCOUNT_SUCCESS':
            return { ...state, loading: false, success: true };
        case 'VALIDATE_ACCOUNT_FAILURE':
            return { ...state, loading: false, error: action.payload };
        default:
            return state;
    }
};

export const canecelSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.CANCEL_CURRENT_SUBSCRIPTION_PLAN_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.CANCEL_CURRENT_SUBSCRIPTION_PLAN_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.CANCEL_CURRENT_SUBSCRIPTION_PLAN_RESET:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.CANCEL_CURRENT_SUBSCRIPTION_PLAN_ERROR:
            return {
                data: state,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const renewSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.RENEW_SUBSCRIPTION_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.RENEW_SUBSCRIPTION_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.RENEW_SUBSCRIPTION_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case searchAccountantType.RENEW_SUBSCRIPTION_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};

export const downgradeSubscriptionReducer = (state = initial_state, action) => {
    switch (action.type) {
        case searchAccountantType.DOWNGRADE_CURRENT_PLANS_DETAILS_LOADING:
            return {
                data: initial_state.data,
                loading: true,
            };
        case searchAccountantType.DOWNGRADE_CURRENT_PLANS_DETAILS_SUCCESS:
            return {
                data: action?.payload,
                loading: false,
            };
        case searchAccountantType.DOWNGRADE_CURRENT_PLANS_DETAILS_RESET:
            return {
                data: initial_state.data,
                loading: false,
            };
        case searchAccountantType.DOWNGRADE_CURRENT_PLANS_DETAILS_ERROR:
            return {
                data: action?.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
};
